import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class FeatureServices extends Component {
    render() {
        return (
            <div className="row align-items-center">
                <div className="col-xl-4 col-lg-4 mb-8 mb-lg-0">
                    <div className={`px-4 py-7 rounded hover-translate text-center ${this.props.bgshadow}`}>
                        <div>
                            <svg width={80} height={80} viewBox="0 0 24 24" fill="none" stroke="#1360ef" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-square">
                                <polyline points="9 11 12 14 22 4" />
                                <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11" />
                            </svg>
                        </div>
                        <h5 className="mt-4 mb-3">Exclusivité</h5>
                        <p>Vous bénéficiez d’une exclusivité de 15 jours calendaires sur le candidat présenté par UNIVERS RH.
                            Durant cette période d’exclusivité, UNIVERS RH s’abstient de proposer ledit candidat à d’autres
                            clients.</p>
                        {/* <Link to="#" className="btn-link">Read Details</Link> */}
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-sm-6">
                    <div className={`px-4 py-7 rounded hover-translate text-center bg-white shadow`}>
                        <div>
                            <svg width={80} height={80} viewBox="0 0 24 24" fill="none" stroke="#1360ef" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-square">
                                <polyline points="9 11 12 14 22 4" />
                                <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11" />
                            </svg>
                        </div>
                        <h5 className="mt-4 mb-3">Non-sollicitation</h5>
                        <p>UNIVERS RH s’abstient d’approcher vos salariés recrutés à travers UNIVERS RH pendant 10 ans.
                            UNIVERS RH s’abstient également d’approcher vos autres salariés sur le territoire français durant la
                            même période de 10 ans.</p>
                        {/* <Link to="#" className="btn-link">Read Details</Link> */}
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-sm-6 mt-6 mt-sm-0">
                    <div className={`px-4 py-7 rounded hover-translate text-center ${this.props.bgshadow}`}>
                        <div>
                            <svg width={80} height={80} viewBox="0 0 24 24" fill="none" stroke="#1360ef" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-square">
                                <polyline points="9 11 12 14 22 4" />
                                <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11" />
                            </svg>
                        </div>
                        <h5 className="mt-4 mb-3">Honoraires</h5>
                        <p>Les honoraires ne sont pas exprimés en pourcentage du salaire annuel du candidat recruté. UNIVERS
                            RH facture un forfait fixe indépendamment du salaire proposé au candidat. Ces honoraires sont
                            perçus uniquement en cas de succès.</p>
                        {/* <Link to="#" className="btn-link">Read Details</Link> */}
                    </div>
                </div>
            </div>

        );
    }
}

export default FeatureServices;