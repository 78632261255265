/**
 *  Main Menu Json
 */
export default [{
   "menu_title": "Accueil",
   "type": "subMenu",
   "path": "/#home",
   "icon": "home",
},
{
   "menu_title": "Offre",
   "path": "/#offres",
   "mega": true,
   "icon": "party_mode",
   "type": "subMenu",
},
{
   "menu_title": "À propos",
   "path": "/#about",
   "mega": true,
   "icon": "party_mode",
   "type": "subMenu",
},
{
   "menu_title": "Contact",
   "path": "/#contact",
   "mega": true,
   "icon": "party_mode",
   "type": "subMenu",
},
]
