import React, { Component } from 'react';
import Herosection3 from '../../widgets/herosection/herosection3';
import Header3 from '../../layout/header/header3';
import FeatureServices from '../../widgets/featurebox/services';
import ContactForm1 from '../../widgets/contact/contactform1';


class Index4 extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div id='home'>
        {/*header start*/}
        <Header3 />
        {/*header end*/}
        {/*hero section start*/}
        <Herosection3 />
        {/*hero section end*/}
        {/*body content start*/}
        <div className="page-content">
          {/*services start*/}
          <section id='offres'>
            <div className="container">
              <div className="row justify-content-center text-center">
                <div className="col-12 col-md-12 col-lg-8 mb-8 mb-lg-0">
                  <div className="mb-8"> <span className="badge badge-primary-soft p-2 font-w-6">
                    Notre offre
                  </span>
                    <h2 className="mt-3 font-w-5">Un service de recrutement avec des garanties et des conditions exclusives</h2>
                    <p className="lead mb-0">Contrairement aux offres classiques présentées par les autres cabinets de recrutement sur le
                      marché, UNIVERS RH vous offre des garanties et des conditions exclusives liées à l’exécution de sa
                      mission.</p>
                  </div>
                </div>
              </div>
              {/* / .row */}
              <FeatureServices />
            </div>
          </section>
          {/*services end*/}
          {/*about start*/}
          <section id='about'>
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-12 col-lg-6 mb-8 mb-lg-0">
                  <img src={require(`../../assets/images/about/03.png`)} alt="Image" className="img-fluid" />
                </div>
                <div className="col-12 col-lg-6 col-xl-5">
                  <div> <span className="badge badge-primary-soft p-2 font-w-6">
                    À propos de nous
                  </span>
                    <h2 className="mt-3 font-w-5">UNIVERS RH</h2>
                    <p className="lead mb-0">UNIVERS RH est un cabinet de recrutement spécialisé dans les métiers qui peinent à recruter. Vous
                      avez des difficultés à recruter ? UNIVERS RH vous apporte la solution !
                      <br />
                      Avec UNIVERS RH, vous bénéficiez de l’accompagnement sur mesure d’un cabinet de recrutement
                      chez vous pour vos recrutements en France et à l&#39;international.
                      Notre maîtrise des méthodologies de recrutement par approche directe ou &quot;headhunting&quot; et notre
                      savoir-faire en tant que cabinet de recrutement international nous permettent de mettre en œuvre
                      une stratégie de recherche parfaitement ciblée.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*about end*/}
          {/*contact start*/}
          <section id="contact">
            <div className="container">
              <div className="row justify-content-center text-center">
                <div className="col-12 col-md-12 col-lg-8 mb-8 mb-lg-0">
                  <div className="mb-8"> <span className="badge badge-primary-soft p-2 font-w-6">
                    Contact
                  </span>
                    {/* <h2 className="mt-3 font-w-5">Creative Design And Creative Services</h2>
                        <p className="lead mb-0">All types of businesses need access to development resources, so we give you the option to decide how much you need to use.</p> */}
                  </div>
                </div>
              </div>
              <div className="row text-center">
                <div className="col-lg-4 col-md-12">
                  <div>
                    <svg className="feather feather-map-pin" xmlns="http://www.w3.org/2000/svg" width={50} height={50} viewBox="0 0 24 24" fill="none" stroke="#1360ef" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" /><circle cx={12} cy={10} r={3} /></svg>
                    <h4 className="mt-5">Addresse:</h4>
                    <span className="text-black">25 PL MARCADAL 65100 LOURDES, FRANCE</span>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div>
                    <svg className="feather feather-mail" xmlns="http://www.w3.org/2000/svg" width={50} height={50} viewBox="0 0 24 24" fill="none" stroke="#1360ef" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" /><polyline points="22,6 12,13 2,6" /></svg>
                    <h4 className="mt-5">Email</h4>
                    <a href="mailto:contact@univers-rh.com">contact@univers-rh.com</a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div>
                    <svg className="feather feather-phone-call" xmlns="http://www.w3.org/2000/svg" width={50} height={50} viewBox="0 0 24 24" fill="none" stroke="#1360ef" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round"><path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg>
                    <h4 className="mt-5">Téléphone</h4>
                    <a href="tel:+33781008653">+ 33 7 81 00 86 53</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <section>
            <div className="container">
              <div className="row justify-content-center mb-5 text-center">
                <div className="col-12 col-lg-8">
                  <div>
                    <h2 className="mt-4 mb-0">Drop A Line</h2>
                    <p className="lead mb-0">Get in touch and let us know how we can help. Fill out the form and we’ll be in touch as soon as possible.</p>
                  </div>
                </div>
              </div>
              <ContactForm1 />
            </div>
          </section> */}
          {/*contact end*/}
        </div>
        {/*body content end*/}
      </div>
    );
  }
}

export default Index4;